.background {
  top: 0px;
  left: 100vw;
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 5;
  display: flex;
  justify-content: center;
  padding: 24px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.background__content {
  width: 100%;
  box-sizing: border-box;
  max-width: 700px;
  padding-top: 18px;
  height: fit-content;
  transition: height 0.2s;
  height: max-content;
  border-radius: 3px;
  min-height: 100vh;
  background-color: rgb(248, 248, 248);
}

.content__subtitle {
  font-size: 18px;
  margin: 0px;
  color: gray;
  font-weight: 500;
  padding: 18px;
  padding-top: 0px;
}

.content__title {
  font-size: 30px;
  margin: 0px;
  padding: 9px 18px;
}

.content__menu {
  height: 45px;
  border: 0px solid #e0e0e0;
  border-top-width: 1px;
  border-bottom-width: 1px;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  margin-top: 9px;
  flex: 1 0 auto;
  touch-action: manipulation;
  justify-content: flex-start;
  align-items: center;
  line-height: 70px;
  display: flex;
  -webkit-overflow-scrolling: auto;
  flex-direction: row;
}

.menu__item {
  cursor: pointer;
  font-size: 16px;
  padding: 0px;
  white-space: nowrap;
  padding-left: 18px;
  height: 45px;
  flex-wrap: nowrap;
  color: black;
  touch-action: manipulation;
  line-height: 45px;
  transition: color 0.1s;
}

.content__prothesis {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: rgb(248, 248, 248);
  height: 25px;
  margin-bottom: 18px;
}

.slider__img {
  width: 1000%;
  /* height: 100%; */
  width: 100%;
  /* box-sizing: border-box; */
  /* margin: 18px; */
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 3px;
  border: 1px solid #f3f3f3;
}

.content__slider {
  width: 100%;
  padding: 18px;
  box-sizing: border-box;
  background-color: white;
  padding-bottom: 0px;
}
