.gallery {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 9px;
  padding-top: 0px;
  padding-bottom: 18px;
  box-sizing: border-box;
  background-color: white;
}

.gallery__container {
  cursor: pointer;
  padding: 9px;
  width: 33.33%;
  height: 33vw;
  max-height: 200px;
  box-sizing: border-box;
}

.container__pic {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
  border: 2px solid black;
}

.background--black {
  height: 100vh;
  left: 0;
  background-color: rgba(29, 37, 45, 0.95);
}

.background__preview-pic {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
}
