$navy: #2d3f50;

@import "header";
@import "map";
@import "results";
@import "ourPage";
@import "modalPage";
@import "gallery";
@import "company";

::-webkit-scrollbar,
div *::-webkit-scrollbar {
  display: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  touch-action: manipulation;
  user-select: none;
  margin: 0;
  padding: 0;
  background-color: rgb(247, 247, 247);
  color: rgb(22, 22, 22);
  font-family: "Roboto Condensed", system-ui, -apple-system, BlinkMacSystemFont,
    “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”,
    “Droid Sans”, “Helvetica Neue”, sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: rgb(238, 138, 44);
  touch-action: manipulation;
}

p {
  line-height: 1.5;
  user-select: text;
}
