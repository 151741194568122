.results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 5px;
}
.results__no-results {
  padding: 18px;
  text-align: center;
  width: 100%;
  font-size: 20px;
}
