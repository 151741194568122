.marker {
  background-color: rgb(231, 69, 60);
  border: 2px solid rgb(190, 52, 45);
  cursor: pointer;
  border-radius: 10px;
  user-select: none;
  position: absolute;
  width: 8px;
  height: 8px;
  left: -5px;
  top: -5px;
  -webkit-transition: background-color 0.5s, transform 0.5s;
  /* Safari */
  transition: background-color 0.5s, transform 0.5s;
}

.marker__label {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0px;
  top: 130px;
  color: white;
  font-size: 12px;
  padding: 5px;
}
