.results__tile {
  user-select: none;
  display: block;
  cursor: pointer;
  width: 25vw;
  max-width: 25%;
  height: 22vw;
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile__container {
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
  min-height: 10vw;
  border: 1px solid rgb(226, 226, 226);
  background-color: rgb(226, 226, 226);
}

.container__img {
  border-radius: 3px;
  width: 100%;
  max-height: 15vw;
  height: auto;
  outline: none;
  border: none;
}

.tile__name {
  font-weight: 500;
  user-select: none;
  text-transform: uppercase;
  font-size: 18px;
  margin: 10px 0px;
  color: rgb(19, 19, 19);
  -webkit-transition: color 0.25s;
  /* Safari */
  transition: color 0.25s;
}

.tile__description {
  font-weight: 400;
  user-select: none;
  font-size: 14px;
  margin: 0px;
  color: gray;
  -webkit-transition: color 0.25s;
  /* Safari */
  transition: color 0.25s;
}

@media screen and (max-width: 700px) {
  .results__tile {
    width: 100vw;
    max-width: 100%;
    height: auto;
  }

  .tile__container {
    min-height: 44vw;
  }

  .container__img {
    max-height: 70vw;
  }
}
