.header {
  z-index: 1;
  background-color: $navy;
  width: 100vw;
  max-width: 100%;
  height: 530px;
  padding-left: 15;
  padding-right: 15;
  position: fixed;
  top: -400;
  padding-top: 400px;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0px solid #2a3a4a;
  /* border-top-width: 1px; */
  border-bottom-width: 1px;
}

.header__block {
  height: 70px;
  box-sizing: border-box;
  overflow-x: hidden;
  padding-top: 15;
  display: flex;
  -webkit-overflow-scrolling: touch;
  flex-direction: row;
}

.header__search-input {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: 1px solid rgba(103, 103, 103, 0.03);
  border-radius: 5px;
  outline: none;
  padding: 15px;
  padding-right: 40px;
  outline: none;
  -webkit-appearance: none;
  font-size: 18px;
  font-family: "Roboto Condensed";
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.block__nav {
  height: 40px;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.nav__item {
  cursor: pointer;
  font-size: 18px;
  padding-left: 35px;
  color: #fff;
  touch-action: manipulation;
  line-height: 40px;
  transition: color 0.1s;
  &:hover {
    color: rgb(26, 162, 255);
  }
}

.header__clear-button {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  bottom: 20px;
  right: 18px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__suggestions {
  position: fixed;
  top: 110;
  left: 0;
  width: 100vw;
  padding: 9px 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: $navy;
  /* border: 0px solid #e0e0e0; */
  /* border-top-width: 1px; */
  border-bottom-width: 1px;
}

.suggestions__suggestion {
  padding: 9px 18px;
  color: white;
  box-sizing: border-box;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    color: rgb(26, 162, 255);
  }
}

@media screen and (max-width: 700px) {
  .header__block {
    overflow-x: scroll;
  }
}
