.our-page {
  display: flex;
  justify-content: center;
  padding: 60px 18px;
}

.our-page__paragraph {
  max-width: 700px;
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  margin: 20px 0px;
}
